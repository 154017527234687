html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.page-wrapper {
    min-height: 100%;
    margin-bottom: -190px;
}
* html .page-wrapper {
    height: 100%;
}
.page-buffer {
    height: 190px;
}
body {
    font-family: 'Roboto', sans-serif;
    min-width: 320px;
    overflow-x: hidden;
}
.clear {
    clear: both;
}
.bold {
    font-weight: bold;
}
a {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.message_error {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    padding: 5px!important;
    text-align: center;
    border: 1px solid!important;
    opacity: 0.9;
}
.message_success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
    text-align: center;
    border: 1px solid;
    margin-bottom: 15px;
    padding: 5px;
    opacity: 0.9;
}
.message_error ul {
    padding-left: 10px;
}
.message_error ul> li {
    text-align: left;
}
.error {
    text-align: center;
    font-size: 100px;
    line-height: 65px;
    margin: 20px 0px 30px;
}
.error1 {
    text-align: center;
    font-size: 21px;
}
.error span {
    font-size: 40px;
}
.container {
    width: 1200px;
}
.navbar {
    border: 0;
    background-color: transparent;
    margin: 0;
}
.nav>li>a {
    padding: 25px 17px;
    font-size: 16px;
    color: #363636;
}
.nav>li>a:hover, .nav>li>a:focus, .nav>li.active>a {
    background-color: transparent!important;
}
.navbar-nav>li {
    float: left;
}
.navbar-nav {
    float: right!important;
    margin: 0 -15px 0 0;
}
.navigation {
    position: relative;
}
.logo {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 1000;
}
.logo img {
    width: 292px;
}
.header {
    max-height: 580px;
    min-height: 91px;
    border: solid 1px #cccccc;
    border-left-color: transparent;
    background: url('../img/headerBg.jpg') no-repeat center 1px;
}
.new_banner .header {
    background: url('../img/headerBg.png') no-repeat center 1px;
    position: relative;
}
.new_banner .header .col-xs-9 {
    position: static;
}
.top_slider {
    position: absolute;
    top: 1px;
    left: 0;
    height: 100%;
    width: 78%;
    z-index: -1;
}
.top_slider .bxslider div, .top_slider .bxslider li {
    height: 100%;
}
.top_slider .bxslider div {
    height: 578px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
.new_banner .bxslider {
    margin: 0;
    height: 578px;
    padding: 0;
}
.phone {
    text-align: right;
    padding-top: 10px;
    color: #000;
    font-size: 23px;
    font-weight: bold;
    padding-right: 0;
}
.phone span {
    font-size: 20px;
}
.phone div {
    display: inline-block;
    position: relative;
    top: 15px;
    margin-right: 5px;
}
.phone img {
    margin-right: 10px;
}
.noPr {
    color: #fff;
    text-align: right;
    text-transform: uppercase;
    font-size: 40px;
    padding-top: 20px;
    font-weight: bold;
    position: relative;
}
.angleOne {
    display: block;
    width: 22px;
    height: 109px;
    position: absolute;
    right: -7px;
    top: 1px;
    background: url('../img/angle-1.png') no-repeat center top;
}
.buttons {
    padding: 70px 0px 70px 15px;
    margin-top: 15px;
    margin-right: -5px;
}
.button {
    margin-bottom: 40px;
    position: relative;
}
.buttons a:nth-child(1) .button div {
    background-color: #8bb8db;
}
.buttons a:nth-child(2) .button div {
    background-color: #6ECFBF;
}
.buttons a:nth-child(3) .button div {
    background-color: #ecbc50;
}
.button div:nth-child(1) {
    display: inline-block;
    width: 88px;
    height: 88px;
    margin-right: 10px;
}
.button div:nth-child(2) {
    display: inline-block;
    height: 88px;
    width: 157px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 29px 0 0 15px;
    text-transform: uppercase;
    vertical-align: top;
    z-index: 15;
}
.angles {
    display: block;
    width: 22px;
    height: 108px;
    position: absolute;
    right: -18px;
    top: 0px;
    z-index: -1;
}
.angles.angleTwo {
    background: url('../img/angle-3.png') no-repeat center top;
}
.angles.angleThree {
    background: url('../img/angle-2.png') no-repeat center top;
}
.angles.angleFour {
    background: url('../img/angle-4.png') no-repeat center top;
}
.buttons a:hover .button div, .buttons a:focus .button div {
    background-color: #FF1218;
}
.buttons a:hover .angles, .buttons a:focus .angles {
    background: url('../img/angle-1.png') no-repeat center top;
}
.buttons a:hover, .buttons a:focus {
    text-decoration: none;
}
.titleLine {
    width: 100%;
    height: 21px;
    background: url('../img/titleLine.png') no-repeat left top;
    margin: 35px 0 25px 0;
}
h4 {
    text-transform: uppercase;
    color: #363636;
    font-size: 25px;
    margin: 0 0 20px 0;
}
.main p {
    margin-bottom: 15px;
    line-height: 1.1;
}
.pluses img {
    margin-bottom: 10px;
}
.pluses p {
    color: #363636;
}
.pluses> .plusItem {
    display: inline-block;
    margin-right: 94px;
    width: 16.5%;
    vertical-align: top;
}
.pluses> .plusItem:nth-last-child(1) {
    margin: 0;
}
.catalog, .pluses {
    padding-top: 10px;
}
.mainCat img:hover {
    cursor: pointer;
}

.catalog img {
    max-width: 100%;
}
.catalog a:hover, .catalog a:focus, .mainCatWrap:hover a {
    text-decoration: none;
}
.newsItem .newsTitle {
    font-weight: bold;
    color: #ff0000;
    margin-bottom: 15px;
    display: block;
}
.newsItem a {
    font-weight: bold;
    color: #ff0000;
}
.newsItem img {
    width: 100%;
}
.newsItem {
    margin-bottom: 35px;
}
.footer {
    background: url('../img/footerBg.jpg') no-repeat center top;
    padding-right: 170px;
    margin-top: 15px;
}
.footer .nav {
    padding-top: 30px;
}
.copyright {
    margin: 10px 0;
}
.copyright, .copyright a {
    color: #7d7d7d;
}
.copyright img {
    margin-right: 5px;
}
.btn-yel {
    padding: 10px 8px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ffbb4c;
    display: block;
    text-align: center;
}
.btn-yel span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}
.btn-yel:hover, .btn-yel:focus {
    background-color: #ff0000;
    text-decoration: none;
    color: #fff;
}
.popularBlock {
    margin-top: 30px;
    min-height: 485px;
    background: url('../img/popularBg.jpg') repeat-y center top;
    background-size: cover;
}
.popularTitle {
    font-size: 31px;
    color: #5f5f5f;
    padding: 20px 15px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    border-bottom: solid 2px #fff;
}
.imageWrapper {
    padding: 10px;
    position: relative;
}
.imageWrapper img {
    display: block;
    margin: 0 auto;
}
.price {
    width: 125px;
    height: 76px;
    background: url('../img/priceImg.png') no-repeat center top;
    background-size: contain;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px;
    padding-left: 28px;
}
.popularBlock p {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}
.price b {
    color: #f3224a;
    display: block;
    text-align: center;
    font-size: 21px;
}
.price p {
    color: #000;
    display: block;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-left: -10px;
    line-height: 1;
}
.btn-cart {
    padding: 10px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ff0000;
    display: block;
    text-align: center;
}
.btn-cart span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}
.btn-cart:hover, .btn-cart:focus {
    background-color: #6ECFBF;
    text-decoration: none;
    color: #fff;
}
.catalogButtons a {
    display: block;
    float: left;
    width: 50%;
    padding: 15px;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
    border: solid 2px transparent;
}
.catalogButtons .typeOne {
    color: #fff;
    background-color: #ff191e;
}
.catalogButtons .typeOne:hover, .catalogButtons .typeOne:focus {
    text-decoration: none;
    background-color: transparent;
    color: #ff191e;
    border-color: #ff191e;
    z-index: 9999;
}
.catalogButtons .typeTwo {
    color: #434343;
    background-color: #ededed;
}
.catalogButtons .typeTwo:hover, .catalogButtons .typeTwo:focus {
    text-decoration: none;
    background-color: transparent;
    color: #434343;
    border-color: #adadad;
    z-index: 9999;
}
.mb40 {
    margin-bottom: 40px;
}
.panel-body, .panel-default {
    padding: 0;
    border: 0!important;
    border-radius: 0;
}
.panel-default>.panel-heading {
    border: 0!important;
    background-color: #6ECFBF;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0;
}
.panel-default>.panel-heading img {
    margin-right: 10px;
}
a.list-group-item {
    border-radius: 0!important;
    border-bottom: solid 2px #f1f1f1;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 16px;
    color: #595959;
}
.catalogItem {
        position: relative;
    float: left;
    width: 25%;
    margin-right: 12.5%;
    margin-bottom: 30px;
}

.catalogItem3 {
    float: left;
    width: 25%;
    margin-right: 12.5%;
    margin-bottom: 30px;
}
.id-3, .id-6, .id-9, .id-12, .id-15, .id-18, .id-21 {
    margin-right: 0;
}
.catalogItem p {
    display: block;
    margin: 0;
    padding: 10px 5px;
    color: #fff;
    text-align: center;
    background-color: #ff0000;
    font-size: 25px;
    font-weight: bold;
}
.catalogItem span {
    display: block;
    margin: 0;
    padding: 10px 21px;
    color: #515151;
    text-align: center;
    background-color: #ededed;
    font-size: 16px;
}

.catalogItem3 p {
    display: block;
    margin: 0;
    padding: 10px 5px;
    color: #fff;
    text-align: center;
    background-color: #ff0000;
    font-size: 25px;
    font-weight: bold;
}
.catalogItem3 span {
    display: block;
    margin: 0;
    padding: 10px 21px;
    color: #515151;
    text-align: center;
    background-color: #ededed;
    font-size: 16px;
}
.catalogItem3:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-color: #ff191e;
    box-shadow: 0 5px 10px 0 rgba(255, 25, 30, .6), 0 5px 14px 0 rgba(255, 25, 30, 0.44);
    border-radius: 3px;
}
.pagination>li>a, .pagination>li>span {
    color: #515151;
    background-color: #ededed;
    font-size: 16px;
}
.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: #6ECFBF;
    border-color: #6ECFBF;
    font-weight: 600;
}
.pagination>.disabled>span, .pagination>.disabled>span:hover, .pagination>.disabled>span:focus, .pagination>.disabled>a, .pagination>.disabled>a:hover, .pagination>.disabled>a:focus {
    color: #929292;
    cursor: not-allowed;
    background-color: rgba(255, 0, 0, 0.14);
}
.contactPage {
    margin-bottom: 20px;
}
.contactPage b {
    font-size: 17px;
    text-transform: uppercase;
}
.default {
    padding: 0!important;
}
.withLink strong, .withLink a, .withLink b {
    font-size: 19px;
}
.withLink a {
    font-size: 26px;
}
.catalogItem {
    border: solid 2px transparent;
}
.mainCatWrap {
    border: solid 2px transparent;
}
.catalogItem:hover, .mainCatWrap:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-color: #ff191e;
    box-shadow: 0 5px 10px 0 rgba(255, 25, 30, .6), 0 5px 14px 0 rgba(255, 25, 30, 0.44);
    border-radius: 3px;
}
.catalogItem:hover span {
    background-color: #FF191E;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalogItem-prof-doors {
    float: left;
    width: 25%;
    margin-right: 12.5%;
    margin-bottom: 30px;
    width: 29%;
    margin-left: 2%;
    margin-right: 2%;
}
.catalogItem-prof-doors .small{
        font-size: 11px;
    background-color: transparent;
    padding: 2px;
}
.catalogItem-prof-doors:hover .small{
        font-size: 11px;
    background-color: transparent!important;
    padding: 2px;
}
.prof-doors-1 {
    width: 30%; height: 10px;  float: left;
}
.prof-doors-1 img {
    width: 60%; margin: 2px 20%;
}
.bxslider li {
    left: 0;
}
.bx-wrapper .imageWrapper img {
    max-height: 302px;
    min-height: 302px;
}
.pdfLinks {
    padding-top: 10px;
    white-space: nowrap;
}
.pdfLinks a {
    font-size: 15px;
    float: left;
    margin: 0 10px;
}
.pdfLinks:after {
    content: '';
    display: table;
    clear: both;
}
.pdfLinks img {
    vertical-align: bottom;
}
.table> tbody> tr> td table {
    display: none;
}
.mobilePhones, .navHeader {
    display: none;
}
.vNalichii .table td[colspan="7"], .vNalichii .table td[colspan="12"] {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.vNalichii .table td[rowspan="3"], .vNalichii .table td[rowspan="4"], .vNalichii .table td[rowspan="5"] {
    vertical-align: middle;
    text-align: center;
}
.vNalichii .table td p {
    margin: 0;
}
.vNalichii .table img {
    height: 200px;
    display: inline-block;
    vertical-align: top;
}
.vNalichii .furnitura img {
    height: auto;
    width: 160px;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    z-index: 2;
    color: #353535;
    background-color: #d4d1cf;
    border-color: #d4d1cf;
}
.table .photo {
    min-width: 240px;
}
.windows {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -5px;
}
.window__item {
	width: 20%;
	padding: 0 5px;
	margin-bottom: 10px;
}
.window__item-title {
	padding: 10px;
    color: #515151;
    text-align: center;
    background-color: #ededed;
    font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}
.window__item img {
	width: 100%;
}
.color-none {
    background-color: #ffffff!important;
}
.catalogItem  .bx-viewport {
    position: initial!important;
}
.fancybox-lock .fancybox-overlay {
    background-color: rgba(0, 0, 0, 0.92)!important;
}
.catalogItem  .bx-prev,.bx-next  {
    display: none;
}
/*ÑÑ‚Ð¸Ð»Ð¸ ÑÐ»Ð°Ð¹Ð´ÐµÑ€Ð°*/

.fancybox-nav span {
    visibility: visible;
}
.btn {
    margin-top: 5px;
}
.newsBigItemImg {
    width: 30%;
    float: left;
    height: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.furn div {
    height: 150px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 5px;
}
.furn {
    text-align: center;
    padding: 5px;
}

.col-xs-4.furn {}
/**/
@media (max-width: 1200px) {
    .mobilePhones {
        display: block;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.63);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .mobilePhones .mp {
        font-size: 18px;
        color: #000;
        font-weight: bold;
    }
    .mobilePhones .mp span {
        font-size: 16px;
    }
    .container {
        width: 100%;
    }
    .header .phone, .header .noPr {
        display: none;
    }
    .header .col-xs-offset-9 {
        width: 100%;
        margin: 0;
    }
    .header {
        max-height: 470px;
        min-height: 0;
        background-position: 0 -90px;
        overflow: hidden;
    }
    .logo img {
        width: 130px;
    }
    .button div:nth-child(2) {
        width: 170px;
    }
    .button {
        margin: 0;
    }
    .buttons {
        margin: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0;
        padding: 20px 10px;
    }
    .button div:nth-child(1) {
        margin: 0;
    }
    .pluses> .plusItem {
        margin-right: 75px;
    }
    .new_banner .header .col-xs-3 {
      width: 100%;
    }
    .new_banner .header {
      background: transparent;
    }
    .top_slider {
      width: 100%;
    }
}
@media (max-width: 992px) {
    .buttons {
        flex-wrap: wrap;
        justify-content: center;
    }
    .mobilePhones {
        flex-wrap: wrap;
    }
    .pluses> .plusItem {
        margin: 0;
        width: 20%;
        text-align: center;
    }
    .pluses {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .page-buffer {
        display: none;
    }
    .page-wrapper {
        margin: 0;
    }
    .panel-body {
        display: none;
    }
    .panel-default>.panel-heading img {
        width: 35px;
    }
    .panel-default>.panel-heading.on {
        background-color: #ffbb4c;
    }
    .list-group-item:focus {
        z-index: 2;
        color: #353535;
        background-color: #d4d1cf;
        border-color: #d4d1cf;
    }
    .catalog {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        /* align-items: flex-end; */
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .catalog .catalogItem {
        width: 100%!important;
        margin: 0 auto 10px auto;
        max-width: 200px;
    }
    .catalog a {
        background-color: transparent;
    }
    .catalog .clear {
        display: none;
    }
    // .mainCatWrap a {
    //     background-color: #ff0000;
    // }
    .col-xs-12.main img:not([usemap]) {
        width: auto!important;
        display: block;
        /* height: auto!important; */
        max-width: 100%;
        margin: 0 auto 10px auto;
    }
    .titleLine {
        margin: 10px 0 15px 0;
    }
}
@media (max-width: 767px) {
    .navbar-nav>li {
        float: none;
        width: 100%;
    }
    .nav>li>a {
        padding: 15px 10px;
        text-align: right;
    }
    .navbar-nav {
        float: none!important;
        margin: 0;
        background-color: #fff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .footer, .navbar {
        display: none;
    }
    .logo img {
        width: 120px;
    }
    .logo {
        left: 0px;
    }
    body {
        padding-top: 66px;
    }
    .container, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
    .navHeader {
        display: block;
        text-align: right;
        height: 66px;
        line-height: 66px;
    }
    .navTrigger {
        width: 42px;
        height: 35px;
        padding: 0 5px;
        border: solid 2px #ccc;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .navTrigger span {
        display: block;
        width: 100%;
        height: 2px;
        margin: 6px 0;
        background-color: #ccc;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .banner {
        padding: 0;
    }
    .header {
        border: 0;
    }
    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        padding: 0;
        height: 66px;
        background-color: #fff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .nav>li>a:hover, .nav>li>a:focus, .nav>li.active>a {
        background-color: #ff0a11!important;
        color: #fff!important;
    }
    .nav>li {
        margin: 2px 0;
    }
    .navTrigger.on {
        border-color: #ff0a11;
    }
    .navTrigger.on span {
        background-color: #ff0a11;
    }
    .navTrigger.on span:nth-child(2) {
        opacity: 0;
    }
    .navTrigger.on span:nth-child(1) {
        transform: rotate(40deg) translate(6px, 7px) scale(1.2);
    }
    .navTrigger.on span:nth-child(3) {
        transform: rotate(-40deg) translate(5px, -5px) scale(1.2);
    }
    .pluses {
        flex-wrap: wrap;
    }
    .pluses> .plusItem {
        width: 50%;
    }
    .mainCatWrap {
        max-width: 200px;
        margin: 0 auto 20px auto;
    }
    .catalogItem {
        width: 50%;
        max-width: 200px;
    }
    object {
        width: 100%!important;
    }
	.window__item {
		width: 50%;
	}
}
@media (max-width: 600px) {
    .mobilePhones {
        justify-content: center;
        text-align: center;
    }
    .mobilePhones {
        padding: 0;
    }
    .mobilePhones .mp {
        width: 100%;
        margin: 5px 0;
    }
    h4 {
        font-size: 20px;
    }
    .pagination li:nth-child(1), .pagination li:nth-child(2), .pagination li:nth-last-child(1), .pagination li:nth-last-child(2) {
        display: none;
    }
}
@media (max-width: 440px) {
    .catalog a {
        font-size: 14px;
        line-height: 14px;
        padding: 10px 5px;
    }
    .catalogItem span {
        padding: 7px 5px;
        font-size: 14px;
    }
    .modal-content {
        width: 100%!important;
    }
    .catalogItem p {
        padding: 7px 5px;
        font-size: 18px;
    }
    .mainCatWrap a {
        width: 100%;
    }
}

.new1 {
        display: block;
    margin: 0!important;
    padding: 0!important;
    color: #fff!important;
    text-align: center!important;
    background-color: #fff!important;
    font-size: 25px;
    font-weight: bold;
}

.newh {
   height:370px;
}
.neww {
   height:465px;
}



.ribbon-green {
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform:    rotate(-45deg);
  -ms-transform:     rotate(-45deg);
  -o-transform:      rotate(-45deg);
  position: relative;
  padding: 7px 0;
left: -31px;
    font-size: 17px;
    top: 11px;
  width: 120px;
  background-color: #ff0000;

  color: #Fff;
}
.ribbon-wrapper-green {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
}

.top_slider  {    top: 90px;}
.bx-viewport {    height: 488px!important;}
.top_slider .bxslider div {
    height: 480px!important;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.double-door {display: flex;    justify-content: space-between;}
.id1-2, .id1-4, .id1-6, .id1-8, .id1-10, .id1-12, .id1-14 {
    margin-right: 0!important;
}

.catalogItem-new {
    position: relative;
    float: left;
    width: 45%;
    min-width: 300px;
    margin-right: 10%;
    margin-bottom: 30px;
}

.catalogItem-new p {

height: 370px;
    width: 173px;
}


@media (max-width: 1200px) {
.top_slider  {
    display: none;
}}

@media (max-width: 619px) {
.id1-2, .id1-4, .id1-6, .id1-8, .id1-10, .id1-12, .id1-14 {
    margin-right: auto!important;
}}

.contacts {display: flex;
    flex-direction: column;}

.panel-heading a {text-decoration: none; color:#fff; }

/* new catalog */


/* category */
form.ajax-form .category-filter {
    width: 100%;
    overflow: hidden;
}

form.ajax-form .category-filter input {
    display: none;
}

form.ajax-form .category-filter label {
    display: block;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    border-bottom: 0;
    transition: linear .2s;
    padding: 16px 0 16px 20px;
}

form.ajax-form .category-filter label span {
    color: #999999;
}

form.ajax-form .category-filter label:last-child {
    border-bottom: 1px solid #e5e5e5;
}

form.ajax-form .category-filter input:checked + label {
    border-color: #ff191e;
    color: #ff191e;
}

form.ajax-form .category-filter input:checked + label + input + label {
    border-top-color: #ff191e;
}

/* price */


form.ajax-form .price-filter {
    border: 1px solid #e5e5e5;
    margin: 25px 0 0 0;
    padding: 15px 0 45px 0;
}

form.ajax-form .price-filter > div {
    padding: 0 30px;
}

form.ajax-form .price-filter p {
    cursor: pointer;
    margin: 0;
    padding: 0 15px;
    font-size: 16px;
}

form.ajax-form .price-filter p > img.arrow {
    width: 13px;
    margin: 0 10px 0 0;
    position: relative;
    top: -1px;
    transition: transform linear .2s;
}

form.ajax-form .price-filter p > img.rub {
    width: 9px;
    position: relative;
    top: -1.4px;
}

form.ajax-form .price-filter .value-price {
    overflow: hidden;
}

form.ajax-form .price-filter .value-price > input {
    width: 50%;
    padding: 6px 0 6px 14px;
    font-size: 16px;
    color: #333;
    border: 1px solid #e5e5e5;
    outline: none;
    margin: 24px 0 0 0;
    transition: linear .2s;
    float: left;
}

form.ajax-form .price-filter .value-price > input:hover,
form.ajax-form .price-filter .value-price > input:focus,
form.ajax-form .price-filter .value-price > input:active {
    border-color: #00bc39;
}

form.ajax-form .price-filter .value-price > input:first-child {
    border-right: 0;
}

form.ajax-form .price-filter .slider-price {
    margin: 15px 0 0 0;
}

form.ajax-form .price-filter .slider-price .ui-widget.ui-widget-content {
    border: 0;
    height: 4px;
    background: #f2f2f2;
    border-radius: 0;
}

form.ajax-form .price-filter .slider-price .ui-widget-header:first-child {
    background: #f2f2f2;
}

form.ajax-form .price-filter .slider-price .ui-widget-header:last-child {
    background: #ff191e;
}

form.ajax-form .price-filter .slider-price .ui-slider .ui-slider-handle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #e5e5e5;
    outline: none;
    top: -7.5px;
    transition: border-color linear .2s;
}

form.ajax-form .price-filter .slider-price .ui-slider .ui-slider-handle.ui-state-hover {
    border-color: #e5e5e5;
}

form.ajax-form .price-filter .slider-price .ui-slider .ui-slider-handle.ui-state-focus {
    border-color: #ff191e;
}

/* factory */

form.ajax-form .factory-filter {
    border: 1px solid #e5e5e5;
    border-top: 0;
    padding: 15px 0 45px 0;
}

form.ajax-form .factory-filter > div.factory-scroll {
    margin: 15px 0 0 0;
    padding: 0 15px;
}

form.ajax-form .factory-filter > div.factory-scroll > div {
    height: 150px;
    overflow: hidden;
}

form.ajax-form .factory-filter p {
    cursor: pointer;
    margin: 0;
    padding: 0 15px;
    font-size: 16px;
}

form.ajax-form .factory-filter p > img.arrow {
    width: 13px;
    margin: 0 10px 0 0;
    position: relative;
    top: -1px;
    transition: transform linear .2s;
}

form.ajax-form .factory-filter input[type=checkbox] {
    display: none;
}

form.ajax-form .factory-filter input[type=checkbox]+label {
    margin: 7px 0 0 0;
    line-height: 1;
    cursor: pointer;
}

form.ajax-form .factory-filter input[type=checkbox]+label > span {
    font-size: 16px;
    color: #333;
    font-weight: normal;
    margin: 0 0 0 10px;
    width: 270px;
    float: left;
}

form.ajax-form .factory-filter input[type=checkbox]+label:before,
form.ajax-form .factory-filter input[type=checkbox]:not(checked)+label:before {
    content: '';
    float: left;
    width: 16px;
    height: 16px;
    background: url('/assets/templates/main/img/checkbox.svg') no-repeat;
    background-size: cover;
    background-position: 0 0;
}

form.ajax-form .factory-filter input[type=checkbox]:checked+label:before {
    background-position: 0 -16px;
}

form.ajax-form .factory-filter .scroll-element.scroll-y,
form.ajax-form .factory-filter .scroll-bar {
    width: 4px !important;
}

form.ajax-form .factory-filter .scroll-element.scroll-y:hover .scroll-element_outer,
form.ajax-form .factory-filter .scroll-element.scroll-y.scroll-draggble .scroll-element_outer {
    min-width: unset;
    width: 4px !important;
}

form.ajax-form .factory-filter .scroll-element.scroll-y:hover .scroll-element_size {

}

form.ajax-form .factory-filter .scrollbar-dynamic > .scroll-element .scroll-element_size {
    opacity: 1;
    top: 0;
}

form.ajax-form .factory-filter .scroll-element.scroll-x {
    height: 4px;
}

form.ajax-form .factory-filter .scroll-element .scroll-element_track,
form.ajax-form .factory-filter .scroll-element .scroll-bar,
form.ajax-form .factory-filter .scroll-element .scroll-element_outer {
    border-radius: 0;
}

/* buttons form */

form.ajax-form .ajax-start {
    width: 100%;
    border: 0;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    margin: 24px 0 0 0;
    outline: none !important;
    padding: 15px 22px;
    background-color: #ff191e;
    cursor: pointer;
    display: inline-block;
    text-decoration: none !important;
    transition: linear .2s;
}

form.ajax-form .ajax-reset {
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 16px;
    color: #e5e5e5;
    font-weight: normal;
    margin: 16px 0 0 0;
    outline: none !important;
    background-color: transparent;
    padding: 7px 14px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none !important;
    transition: linear .2s;
}

/* ajax-item */

.new_catalog .ajax-filter-count .ajax-more {
    font-size: 16px;
    color: #434343;
    font-weight: bold;
    text-decoration: none;
    background-color: #ededed;
    display: inline-block;
    text-transform: uppercase;
    padding: 19px 35px;
    margin: 25px 0 0 0;

}

.new_catalog .ajax-item {
    margin-bottom: 30px;
}

.new_catalog .ajax-item a {
    overflow: hidden;
    display: block;
    text-decoration: none;
}

.new_catalog .ajax-item a .image {
    background-size: cover;
    background-position: top center;
    height: 335px;
}

.new_catalog .ajax-item a .name {
    margin: 0;
    color: #515151;
    font-size: 16px;
    text-align: center;
    background-color: #ededed;
    padding: 12.5px 0;
}

.new_catalog .ajax-item .price-item {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #ff191e;
    padding: 8.5px 0;
}

/* product page */

.product {

}

.product_slider_wrap {

}

.product_slider_wrap .bx-viewport {
    height: 460px !important;
    border: 1px solid #e5e5e5;
    box-shadow: none;
}

.product_slider_wrap .bx-wrapper .bx-controls-direction a {
    display: block !important;
    transition: none !important;
    width: 32px;
    height: 54px;
    background: url('/assets/templates/main/img/arrow.png') no-repeat;
    background-size: contain;
}

.product_slider_wrap .bx-wrapper .bx-controls-direction a {
    z-index: 10;
}

.product_slider_wrap .bx-wrapper .bx-controls-direction a.bx-next {
    transform: rotate(180deg);
    right: 25px;
}

.product_slider_wrap .bx-wrapper .bx-controls-direction a.bx-prev {
    left: 25px;
}

.product_slider {
    width: 100%;
    height: 460px !important;
}

.product_slider > div {
    width: 100%;
    height: 100%;
    display: flex;
}

.product_slider > div > div {
    width: 190px;
    height: 400px;
    margin: auto;
    background-size: cover;
    background-position: top center;
}

.product_info {

}

.product_info .product_buy {
    width: 100%;
    height: 110px;
    border: 1px solid #e5e5e5;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.product_info .product_buy .price-product {
    font-size: 36px;
    color: #ff191e;
    font-weight: 500;
    margin: 0;
}

.product_info .product_buy .buy {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    background-color: #ff191e;
    text-align: center;
    width: 49%;
    padding: 16.5px 0;
}

.product_info .product_descr {
    margin: 30px 0 0 0;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    padding: 20px 25px;
}

.product_info .product_descr h6 {
    font-size: 19px;
    color: #000;
    margin: 0 0 10px 0;
}

.product_info .product_descr p {
    margin: 3px 0 0 0;
    font-size: 16px;
    color: #333333;
}

.product_info .product_descr p b {
    color: inherit;
    font-size: inherit;
}

.product .more_products {
    font-size: 24px;
    color: #000;
    margin: 45px 0 25px 0;
    font-weight: 500;
    text-transform: unset;
}

.product .new_catalog {
    margin-bottom: 60px;
}
.halva{
        padding: 5px 10px;
    border: 1px solid #ff0a11;
    color: #000;
    margin: 30px 0;
    display: inline-block;
    font-weight: bold;

}
.halva:hover{
    text-decoration: none;
}

.fancybox-navigation .fancybox-button:after {
    background: transparent!important;
}
.top_slider .bx-viewport{
    box-shadow: none;
}

.params-section__row{
    display: flex;
    justify-content: flex-start;
}
.params-section__term{
    font-weight: bold;
    min-width: 50%;
    margin-bottom: 20px;
}
